import React from 'react';
import { BadgeBase } from 'wix-ui-tpa/cssVars';
import classNames from 'clsx';
import type { IWutBadgeProps } from '../WutBadge.props';
import { isIconComposition, isTextComposition } from '../utils/WutBadgeUtils';
import type { CompositionType } from '../utils/WutBadgeUtils';
import { TestIds } from '../constants';
import { classes, st } from './style/WutBadge.component.st.css';

const generateIconElement = (
  tempIcon: { svgId?: string },
  alt: string,
  composition: CompositionType,
) => {
  if (!!tempIcon && tempIcon.svgId && isIconComposition(composition)) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: tempIcon.svgId }}
        title={alt}
        className={classes.icon}
        data-testid={TestIds.badgeIcon}
      />
    );
  }
  return;
};

/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabilities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const Badge: React.FC<IWutBadgeProps> = props => {
  const { id, text, icon, composition, alt, className } = props;
  const { stylableClassName } = props as any;

  return (
    <div
      id={id}
      aria-label={alt}
      className={classNames(className, classes.outerWrapper)}
      data-testid={TestIds.wrapper}
    >
      <BadgeBase
        icon={generateIconElement(icon, alt, composition)}
        className={st(classes.root, stylableClassName)}
        data-testid={TestIds.baseBadge}
      >
        {isTextComposition(composition) && (
          <span
            id={`text-${id}`}
            className={classes.text}
            data-testid={TestIds.badgeText}
          >
            {text}
          </span>
        )}
      </BadgeBase>
    </div>
  );
};

export default Badge;
